// Breakpoint Variables
$breakpoint-xss: 0;
$breakpoint-xs: 375px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xll: 1360px;
$breakpoint-xxl: 1400px;

// Mixin for Media Queries
@mixin breakpoint($point) {
  @if $point == xss {
    @media (max-width: #{$breakpoint-sm - 0.02}) {
      @content;
    }
  } @else if $point == xs {
    @media (min-width: #{$breakpoint-sm}) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: #{$breakpoint-sm}) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: #{$breakpoint-md}) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: #{$breakpoint-lg}) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: #{$breakpoint-xl}) {
      @content;
    }
  } @else if $point == xxl {
    @media (min-width: #{$breakpoint-xxl}) {
      @content;
    }
  }
}
