@font-face {
    font-family: 'AcidGrotesk-OneQrew-Medium';
    src: url('../fonts/AcidGrotesk-OneQrew-Medium.ttf') format('truetype');
    font-display: swap;
  }

@font-face {
    font-family: 'AcidGrotesk-OneQrew-BookItalic';
    src: url('../fonts/AcidGrotesk-OneQrew-BookItalic.ttf') format('truetype');
    font-display: swap;
  }

@font-face {
    font-family: 'AcidGrotesk-OneQrew-Book';
    src: url('../fonts/AcidGrotesk-OneQrew-Book.ttf') format('truetype');
    font-display: swap;
  }

  :root {
    --font-bold: 'AcidGrotesk-OneQrew-Medium', sans-serif;
    --font-italic: 'AcidGrotesk-OneQrew-BookItalic', sans-serif;
    --font-book: 'AcidGrotesk-OneQrew-Book', sans-serif;
  }
