$highlight-blue: #00294d;
$light-blue: #2a79c6;
$orange: #ffa468;
$dark-orange: #ff6600;
$white: #fff;
$deep-purple: #190023;
$black: #000;
$light-grey: #f4f3ee;
$grey: #e4e4df;
$dark-grey: #4b4b4b;
$dark-grey-2: #6c6c66;
$red: #ff0000;
$highlight: #ff6600;

$primary-color: $highlight;
$secondary-color: $orange;
$tertiary-color: $dark-grey;
