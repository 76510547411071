/* Modern CSS Reset */

/* Box sizing */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Remove default margin and padding */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

/* Remove list styles (bullets/numbers) */
ol,
ul {
  list-style: none;
}

/* Remove default hyperlink styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Remove default button styles */
button:not(.p-button) {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

input,
textarea {
  cursor: pointer;
  font-family: unset;
}

/* Disable tap highlight on mobile */
a,
button {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}
