@import '../config/breakpoints';

// Font Size
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-smd: 16px;
$font-md: 18px;
$font-h4: 24px;
$font-h3: 36px;
$font-h2: 54px;

// Font Weight
$font-weight-light: 350;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Line Height
$line-height-xs: 21.6px;
$line-height-sm: 22px;
$line-height-md: 29px;
$line-height-h3: 43px;
$line-height-h2: 65px;

// Typography Mixins
@mixin font-setup($size, $weight, $line-height, $font-family: var(--font-bold)) {
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
  font-family: $font-family;
}

body,
p,
.p {
  @include font-setup($font-md, $font-weight-light, $line-height-md, var(--font-book));
  color: $tertiary-color;
}

.p-button {
  @include font-setup($font-md, $font-weight-light, $line-height-md, var(--font-book));
}

h1,
h2,
.h1,
.h2 {
  @include font-setup($font-h3, $font-weight-medium, $line-height-h3);

  @include breakpoint(sm) {
    @include font-setup($font-h2, $font-weight-medium, $line-height-h2);
  }
}

h3,
.h3 {
  @include font-setup($font-h4, $font-weight-medium, $line-height-md);

  @include breakpoint(sm) {
    @include font-setup($font-h3, $font-weight-medium, $line-height-h3);
  }
}

h4,
.h4 {
  @include font-setup($font-md, $font-weight-light, $line-height-md);

  @include breakpoint(sm) {
    @include font-setup($font-h4, $font-weight-medium, $line-height-md);
  }
}

.big-text {
  @include font-setup($font-md, $font-weight-light, $line-height-md, var(--font-book));

  @include breakpoint(sm) {
    @include font-setup($font-h4, $font-weight-medium, $line-height-md, var(--font-book));
  }
}

b,
strong {
  font-family: var(--font-bold);
  font-weight: $font-weight-bold;
}

.smedium-text {
  @include font-setup($font-size-smd, $font-weight-light, $line-height-xs, var(--font-book));
}

.small-text {
  @include font-setup($font-size-sm, $font-weight-light, $line-height-sm, var(--font-medium));
}

button {
  font-family: var(--font-book);
}

.text {
  // Text Color
  &-white {
    color: $white;
  }

  &-black {
    color: $black;
  }

  &-primary-color {
    color: $primary-color;
  }

  &-highlight {
    color: $secondary-color;
  }

  &-light-orange {
    color: $orange;
  }

  &-dark-orange {
    color: $dark-orange;
  }

  &-dark-grey {
    color: $tertiary-color;
  }

  &-dark-grey-2 {
    color: $dark-grey-2;
  }

  &-light-grey {
    color: $light-grey;
  }

  &-red {
    color: $red;
  }

  // Text Alignment
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  // Font Size
  &-xs {
    font-size: $font-size-xs;
  }

  &-sm {
    font-size: $font-size-sm;
  }

  &-md {
    font-size: $font-md;
  }

  // Font Weight
  &-light {
    font-weight: $font-weight-light;
  }

  &-regular {
    font-weight: $font-weight-regular;
  }

  &-medium {
    font-weight: $font-weight-medium;
    font-family: var(--font-bold);
  }

  &-bold {
    font-weight: $font-weight-bold;
    font-family: var(--font-bold);
  }

  // Text Decoration
  &-strike {
    text-decoration: line-through;
  }

  &-underlined {
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  &-header-underlined {
    display: inline-block;
    position: relative;
  }

  &-header-underlined::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 6px;
    background-color: $dark-orange;
  }
}

label.required::after,
span.required::after {
  content: '*';
  color: $red;
  margin-left: 0.1rem;
}

a {
  &:hover {
    color: $secondary-color;
    transition: 0.2s ease-in-out;
  }
}
